// src/utils/parseJobRequest.js

export function parseJobRequest(dataString) {
  const titlePattern = /Title:\s*(.*)/;
  const descriptionPattern = /Description:\s*([\s\S]*?)\s*PropertyId:/;
  const addressPattern = /PropertyId:\s*(.*)/;
  const categoryPattern = /Category:\s*(.*)/;
  const availabilityPattern = /Availability:\s*(.*)/;
  const sendToPattern = /Send to:\s*(.*)/;

  const titleMatch = dataString.match(titlePattern);
  const descriptionMatch = dataString.match(descriptionPattern);
  const addressMatch = dataString.match(addressPattern);
  const categoryMatch = dataString.match(categoryPattern);
  const availabilityMatch = dataString.match(availabilityPattern);
  const sendToMatch = dataString.match(sendToPattern);

  return {
    propertyId: addressMatch ? addressMatch[1].trim() : null, // Trim whitespace
    title: titleMatch ? titleMatch[1].trim() : null, // Trim whitespace
    category: categoryMatch ? categoryMatch[1].trim() : null, // Trim whitespace
    description: descriptionMatch ? descriptionMatch[1].trim() : null, // Trim whitespace
    sendMarket: sendToMatch ? sendToMatch[1].trim() : null, // Trim whitespace
    availability: availabilityMatch ? availabilityMatch[1].trim() : null, // Trim whitespace
  };
}
