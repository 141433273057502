import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import styles from "./SubmitBidForm.module.css";

const PriceInput = (props) => {
  return (
    <Grid container spacing={3}>
      <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
        <RadioGroup
          row={true}
          onChange={(e) => props.setPriceType(e.target.value)}
        >
          <FormControlLabel
            value="Labour"
            control={<Radio />}
            label="Labour Charge"
          />
          <FormControlLabel
            value="Materials"
            control={<Radio />}
            label="Material Charge"
          />
        </RadioGroup>
      </FormControl>

      {props.priceType === "Labour" && (
        <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
          <RadioGroup
            row={true}
            onChange={(e) => props.setLabourType(e.target.value)}
          >
            <FormControlLabel
              value="Hourly"
              control={<Radio />}
              label="Hourly Rate?"
            />
            <FormControlLabel
              value="Flat"
              control={<Radio />}
              label="Flat Rate?"
            />
          </RadioGroup>
        </FormControl>
      )}

      {props.priceType === "Labour" && props.labourType === "Hourly" && (
        <>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Hours</InputLabel>
              <OutlinedInput
                required
                id="hours"
                type="hours"
                name="hours"
                onChange={(e) => props.setHours(e.target.value)}
                placeholder="Hours"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Price</InputLabel>
              <OutlinedInput
                required
                id="price"
                type="price"
                name="price"
                onChange={(e) => props.setPrice(e.target.value)}
                placeholder="Price"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                multiline
                required
                id="description"
                type="description"
                name="description"
                onChange={(e) => props.setDescription(e.target.value)}
                placeholder="Work description"
              />
            </Stack>
          </Grid>
        </>
      )}
      {props.priceType === "Labour" && props.labourType === "Flat" && (
        <>
          <Grid item xs={12} md={12}>
            <Stack spacing={1} margin="0 0 1rem 0">
              <InputLabel>Price</InputLabel>
              <OutlinedInput
                required
                id="price"
                type="price"
                name="price"
                onChange={(e) => props.setPrice(e.target.value)}
                placeholder="Price"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                multiline
                required
                id="description"
                type="description"
                name="description"
                onChange={(e) => props.setDescription(e.target.value)}
                placeholder="Work description"
              />
            </Stack>
          </Grid>
        </>
      )}
      {props.priceType === "Materials" && (
        <>
          <Grid item xs={12} md={12}>
            <Stack spacing={1} margin="0 0 1rem 0">
              <InputLabel>Price</InputLabel>
              <OutlinedInput
                required
                id="price"
                type="price"
                name="price"
                onChange={(e) => props.setPrice(e.target.value)}
                placeholder="Price"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                multiline
                required
                id="description"
                type="description"
                name="description"
                onChange={(e) => props.setDescription(e.target.value)}
                placeholder="Materials description"
              />
            </Stack>
          </Grid>
        </>
      )}

      <Button
        size="sm"
        variant="contained"
        color="primary"
        sx={{ ml: 2, mt: 2 }}
        type="button"
        onClick={() => props.addSubTotal()}
      >
        Add To Cost
      </Button>
    </Grid>
  );
};

const KamvosCut = ({ totalCost, charge }) => {
  // console.log(totalCost, charge);
  let totalServiceCharge = (
    parseFloat(totalCost) * parseFloat(charge / 100)
  ).toFixed(2);
  let serviceChargeTax = parseFloat(0.15 * totalServiceCharge).toFixed(2);
  let serviceFeeWithTax = parseFloat(
    parseFloat(totalServiceCharge) + parseFloat(serviceChargeTax)
  ).toFixed(2);

  let hstCollected = parseFloat(totalCost * 0.15).toFixed(2);

  let totalPayOut =
    parseFloat(totalCost) +
    parseFloat(hstCollected) -
    parseFloat(serviceFeeWithTax).toFixed(2);
  // console.log(serviceFeeWithTax);
  // console.log(totalServiceCharge);
  // console.log(serviceChargeTax);
  return totalCost !== 0 ? (
    <>
      <Grid
        xs={12}
        width="100%"
        height="fit-content"
        className={styles.totalRow}
      >
        <Grid xs={7}>
          <p style={{ margin: "0" }}>Service Charge</p>
        </Grid>

        <Grid xs={4} textAlign="right" paddingRight={2}>
          ${serviceFeeWithTax}
        </Grid>

        <Grid xs={1}>&nbsp;</Grid>
      </Grid>

      <Grid
        xs={12}
        width="100%"
        height="fit-content"
        className={styles.totalRow}
      >
        <Grid xs={7}>
          <p style={{ margin: "0" }}>HST collected</p>
        </Grid>
        <Grid xs={4} textAlign="right" paddingRight={2}>
          ${hstCollected}
        </Grid>

        <Grid xs={1}>&nbsp;</Grid>
      </Grid>

      <Grid
        xs={12}
        width="100%"
        height="fit-content"
        className={styles.totalRow}
      >
        <Grid xs={7}>&nbsp;</Grid>
        <Grid
          xs={4}
          borderTop={"solid black 2px"}
          textAlign="right"
          paddingRight={2}
        >
          ${totalPayOut}
        </Grid>
        <Grid xs={1}>&nbsp;</Grid>
      </Grid>
    </>
  ) : (
    ""
  );
};

const SubmitBidForm = (props) => {
  const [price, setPrice] = useState(0);
  const [hours, setHours] = useState(0);
  const [description, setDescription] = useState(0);
  const [priceType, setPriceType] = useState("");
  const [labourType, setLabourType] = useState("");
  const [total, setTotal] = useState([]);
  const [notes, setNotes] = useState("");
  const [workdone, setWorkdone] = useState("");
  const [showCostInput, setShowCostInput] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    localStorage.removeItem(`submitBidFromData#${props.openJR.job_id}`);
    setSubmitting(true);
    if (total.length !== 0) {
      let labourCharge;
      let estimatedHours;
      let estimatedMaterials;
      for (let i in total) {
        if (total[i].labour === "Hourly") {
          labourCharge = `${total[i].price}`;
          estimatedHours = total[i].hours;
        } else if (total[i].labour === "Flat") {
          labourCharge = total[i].price;
          estimatedHours = null;
        }
        if (total[i].priceType === "Materials") {
          estimatedMaterials = total[i].price;
        }
      }
      const chargeList = total.map((item) => {
        let amount;
        let quantity;
        let type;
        let description;
        if (item.total === null) return null;
        if (item.labour === "Hourly") {
          amount = `${item.price}`;
          quantity = item.hours;
          type = "Hourly";
        } else if (item.labour === "Flat") {
          amount = item.price;
          quantity = null;
          type = "Flat";
        } else if (item.priceType === "Materials") {
          amount = item.price;
          quantity = null;
          type = "Materials";
        }
        description = item.description || null;
        return { amount, quantity, type, description };
      });

      const bidFormData = {
        jobId: props.id,

        labourCharge: labourCharge,
        estimatedHours: estimatedHours,
        estimatedMaterials: estimatedMaterials,
        bidAmnt: calcTotalCost(),
        notes: notes,
        workdone: workdone,
        clientID: props.clientID,

        chargeList,
        host: window.location.host,
      };
      console.log(bidFormData, "BIDFORMDATA");
      axios.post("/bidding/startBidding", bidFormData).then((res) => {
        props.onClose();
        props.fetchData();
      });
    }
  };

  const addSubTotal = () => {
    if (isNaN(price) || isNaN(hours) || price <= 0) {
      return;
    }
    const newTotal = {
      id: total.length,
      priceType: priceType,
      price: price,
      description: null,
      labour: null,
      total: null,
    };
    if (priceType === "Labour") {
      newTotal.labour = labourType;
      if (labourType === "Hourly") {
        if (hours <= 0) {
          return;
        }
        newTotal.hours = hours;
        newTotal.total = price * hours;
      } else {
        newTotal.total = price;
      }
    } else {
      newTotal.total = price;
    }
    newTotal.description = description;
    setTotal([...total, newTotal]);
    setShowCostInput(false);
  };

  const removeSubTotal = (ID) => {
    const newTotal = total.filter((t) => {
      return t.id !== ID;
    });
    setTotal(newTotal);
  };

  const calcTotalCost = () => {
    let totalCost = 0;
    for (let subTotal of total) {
      // console.log(subTotal);
      if (subTotal.total === null) continue;
      if (subTotal === -1) continue;
      totalCost += parseFloat(subTotal.total);
    }
    return totalCost;
  };

  const saveFormDataToLocal = () => {
    localStorage.removeItem(`submitBidFromData#${props.openJR.job_id}`);
    if (props.openJR.job_description.trim() !== "" && total.length !== 0) {
      let chargesData = [];

      for (let i in total) {
        let charge = {};
        if (total[i].labour === "Hourly") {
          charge = {
            id: i,
            type: total[i].priceType,
            hours: total[i].hours,
            labour: total[i].labour,
            price: total[i].price,
            priceType: total[i].priceType,
            description: total[i].description,
            total: total[i].hours * total[i].price,
          };
        } else if (total[i].labour === "Flat") {
          charge = {
            id: i,
            type: total[i].priceType,
            hours: null,
            labour: total[i].labour,
            price: total[i].price,
            priceType: total[i].priceType,
            description: total[i].description,
            total: total[i].price,
          };
        } else if (total[i].priceType === "Materials") {
          charge = {
            id: i,
            type: total[i].priceType,
            price: total[i].price,
            priceType: total[i].priceType,
            description: total[i].description,
            total: total[i].price,
          };
        }

        chargesData.push(charge);
      }

      const formDataToSave = {
        workToDo: workdone,
        jobId: props.openJR.job_id,
        notes: notes,
        date: new Date(),

        chargesData: chargesData,
      };
      localStorage.setItem(
        `submitBidFromData#${props.openJR.job_id}`,
        JSON.stringify(formDataToSave)
      );
    }
  };

  const getFormDataFromLocal = useCallback(() => {
    const savedFormData = localStorage.getItem(
      `submitBidFromData#${props.openJR.job_id}`
    );

    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      let saveDate = new Date(parsedFormData.date);
      saveDate.setDate(new Date(parsedFormData.date).getDate() + 5);
      if (saveDate < new Date()) {
        localStorage.removeItem(`submitBidFromData#${props.openJR.job_id}`);
      } else {
        // console.log(parsedFormData);
        // console.log(parsedFormData.notes);
        // console.log(parsedFormData.chargesData);
        setWorkdone(parsedFormData.workToDo);
        setNotes(parsedFormData.notes);
        setTotal(parsedFormData.chargesData);
      }
    }
  }, [props.openJR.job_id]);

  useEffect(() => {
    getFormDataFromLocal();
  }, [getFormDataFromLocal]);

  return (
    <>
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3} padding={0}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Required Workdone(estimated)</InputLabel>
              <OutlinedInput
                required
                id="workdone"
                name="workdone"
                value={workdone}
                onChange={(e) => setWorkdone(e.target.value)}
                placeholder={"Work to be done"}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} padding={0} margin={0}>
            <Stack padding={0} margin={0}>
              <InputLabel>
                <button
                  style={{ marginRight: "0.5rem" }}
                  name="showCostInput"
                  type="button"
                  className={styles.addBtn}
                  onClick={() => {
                    setShowCostInput(true);
                  }}
                >
                  +
                </button>
                {"Add Charges"}
              </InputLabel>
            </Stack>
          </Grid>

          {total.length > 0 && (
            <Grid xs={12} md={12} marginLeft="2em">
              <Grid container width="100%" height="fit-content">
                {total.map((subTotal, i) => {
                  subTotal.id = i;
                  let subTotalType;
                  if (subTotal.total === null) {
                    return null;
                  } else if (subTotal.priceType === "Labour") {
                    subTotalType = "Labour ";
                    subTotalType +=
                      subTotal.labour === "Hourly"
                        ? `${subTotal.hours} hours at `
                        : "flat rate ";
                  } else {
                    subTotalType = "Materials at";
                  }
                  return (
                    <Grid
                      xs={12}
                      width="100%"
                      height="fit-content"
                      key={subTotal.id}
                      className={styles.totalRow}
                    >
                      <Grid xs={7}>
                        <p style={{ margin: "0" }}>
                          {subTotalType} ${subTotal.total}
                        </p>
                        {subTotal.description && (
                          <span>&nbsp;({subTotal.description})</span>
                        )}
                      </Grid>
                      <Grid xs={4} textAlign="right" paddingRight={2}>
                        {`$${parseFloat(subTotal.total).toFixed(2)}`}
                      </Grid>
                      <Grid xs={1}>
                        <IconButton
                          aria-label="delete"
                          size="medium"
                          style={{
                            justifySelf: "flex-end",
                          }}
                          onClick={() => removeSubTotal(subTotal.id)}
                        >
                          <CloseOutlined
                            style={{
                              color: "#FF0000",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                {console.log(props.chargePercentages)}

                <KamvosCut
                  totalCost={calcTotalCost()}
                  charge={props.chargePercentages.vendorPercentage}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Extra Notes</InputLabel>
              <OutlinedInput
                required
                id="notes"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder={"Extra notes"}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            {submitting && (
              <Typography component="h4" color={"#7c7c7c"}>
                submitting...
              </Typography>
            )}
            <AnimateButton>
              <Button
                disabled={submitting}
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ ml: 2, float: "right" }}
              >
                Submit
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                sx={{ ml: 2, float: "right" }}
                onClick={saveFormDataToLocal}
              >
                Save
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>

      <Modal
        destroyOnClose={true}
        width="350px"
        title="Add to cost"
        open={showCostInput}
        onCancel={() => setShowCostInput(false)}
        footer={false}
      >
        {showCostInput && (
          <PriceInput
            priceId={0}
            price={price}
            hours={hours}
            priceType={priceType}
            labourType={labourType}
            setPrice={setPrice}
            setHours={setHours}
            setDescription={setDescription}
            setPriceType={setPriceType}
            setLabourType={setLabourType}
            addSubTotal={addSubTotal}
          />
        )}
      </Modal>
    </>
  );
};

export default SubmitBidForm;
